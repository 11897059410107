<template>
  <div class="my-second realname bgcolor">
      <Head :title="$t('info.user_real')" :show="true" ></Head>
      <div class="common-box realname-box">
          <div class="box-one">
              <h3>{{$t('info.real_progerss')}}</h3>
              <p>{{$t('info.keep_money')}}</p>
              <div class="steps">
                  <div class="steps-box">
                      <div class="steps-box-num">
                          <p class="num" :class="current>=0?'active':'0'">1</p>
                          <p class="line" :class="current>=1?'active':'0'"></p>
                          <p class="num" :class="current>=1?'active':'0'">2</p>
                          <p class="line" :class="current>=2?'active':'0'"></p>
                          <p class="num" :class="current>=2?'active':'0'">3</p>
                      </div>
                      <div class="steps-box-text">
                          <p :class="current>=0?'active':'0'">{{$t('info.info_input')}}</p>
                          <p :class="current>=1?'active':'0'">{{$t('info.info_cheek')}}</p>
                          <p :class="current>=2?'active':'0'">{{$t('info.info_padd')}}</p>
                      </div>
                  </div>
              </div>
              <div class="card">
                  <h2>{{$t('info.upload_idcard')}}</h2>
                   <div class="card-item ">
                       <div v-if="current==0"  class="card-one card-zheng img-box" @click="addFrontImg">
                           <input  type="file" name="file" id="download" ref="download" style="display:none" @change="FrontImgChange()" />
                           <img class="img-big" v-if="!front_img" :src="require('../../../assets/images/front.png')" alt="">
                           <img class="img-big" v-if="front_img" :src="front_img" alt="">
                           <div class="card-center" >
                                <img class="img-photo" src="../../../assets/images/photo.png" alt="">
                                <p class="p-item color-main">{{$t('info.upload_idcard_one')}}</p>
                           </div>
                       </div>
                       <div v-if="current==1" class="card-one card-zheng img-box">
                           <img class="img-big" :src="identity_font" alt="">
                           <div class="card-center card-mask">
                                <img class="img-photo" src="../../../assets/images/doing.png" alt="">
                                <p class="p-item color-white">{{toggle? $t('info.checking'): $t('info.check_fail')}}</p>
                           </div>
                       </div>
                       <div v-if="current==2" class="card-one card-zheng img-box">
                           <img class="img-big" :src="identity_font" alt="">
                           <div class="card-center card-mask">
                                <img class="img-photo" src="../../../assets/images/success.png" alt="">
                                <p class="p-item color-white">{{$t('info.check_pass')}}</p>
                           </div>
                       </div>
                       <p>
                           {{$t('info.idcard_one_tips')}}
                       </p>
                   </div>   
                   <div class="card-item ">
                        <div  v-if="current==0" class="card-one card-fang img-box" @click="addBackImg">
                            <input  type="file" name="file" id="back" ref="back" style="display:none" @change="backImgChange()" />
                            <img class="img-big" v-if="!back_img" src="../../../assets/images/back.png" alt="">
                            <img class="img-big" v-if="back_img" :src="back_img" alt="">
                            
                            <div class="card-center">
                                <img class="img-photo" src="../../../assets/images/photo.png" alt="">
                                <p class="p-item color-main">{{$t('info.upload_idcard_two')}}</p>
                            </div>
                       </div>
                       <div v-if="current==1" class="card-one card-zheng img-box">
                           <img class="img-big" :src="identity_back" alt="">
                           <div class="card-center card-mask">
                                <img class="img-photo" src="../../../assets/images/doing.png" alt="">
                                <p class="p-item color-white">{{toggle?$t('info.checking'): $t('info.check_fail')}}</p>
                           </div>
                       </div>
                       <div v-if="current==2" class="card-one card-zheng img-box">
                           <img class="img-big" :src="identity_back"  alt="">
                           <div class="card-center card-mask">
                                <img class="img-photo" src="../../../assets/images/success.png" alt="">
                                <p class="p-item color-white">{{$t('info.check_pass')}}</p>
                           </div>
                       </div>
                       <p>
                           {{$t('info.idcard_two_tips')}}
                       </p>
                   </div>    
              </div>
          </div>
          <div class="box-two">
              <van-form>
                  <van-field :label="$t('info.real_name')"  :disabled="ableFlag"  v-model="first_name" :placeholder="$t('info.keep_name_idcard')"  />
                  <van-field :label="$t('info.id_card')" :disabled="ableFlag" v-model="identity_num" :placeholder="$t('info.input_idcard')"  />
              </van-form>
          </div>
          <van-button class="confirm-btn" :style="{background:disabled?'#cccccc':''}" @click="gotoconfirm" :disabled="disabled">{{$t('login.confirm')}}</van-button>
      </div>
  </div>
</template>

<script>
import {Toast} from "vant"
export default {
    data(){
        return {
            ableFlag:false,
            obj:{},
            identity_num:'',
            first_name:'',
            current:0,
            front_img:null,
            back_img:null,
            identity_font:null,
            identity_back:null,
            disabled:false,
            toggle:true ,  // 第二部审核失败
        }
    },
    mounted(){
        this.checkKyc();
    },
    methods:{
        checkKyc(){
            this.$ajax.showkyc()
            .then(res=>{
                // 0为未申请认证，1为已认证, 2 拒绝认证（审核不通过）, 3 审核中
                if(res.code==200){
                    let {is_kyc,identity_font,identity_back,identity_num,phone_number,first_name} = res.data;
                    if(is_kyc==0){
                        this.current = 0;
                    }else if(is_kyc==3){
                        //  3 审核中
                        this.current = 1;
                        this.identity_font = identity_font;
                        this.identity_back = identity_back;
                        this.disabled = true; 
                        this.first_name = first_name;
                        this.identity_num = identity_num;
                        this.ableFlag = true;
                    }else if(is_kyc==1){
                        //1 审核成功 
                        this.current = 2;
                        this.identity_font = identity_font;
                        this.identity_back = identity_back;
                        this.disabled = true; 
                        this.first_name = first_name;
                        this.identity_num = identity_num;
                        this.ableFlag = true;
                    }else{
                        // 2 审核失败
                        this.current = 1;
                        this.identity_font = identity_font;
                        this.identity_back = identity_back;
                        this.disabled = false; 
                        this.toggle = false;
                        this.ableFlag = false;
                    }
                
                }
            })
        },
        gotoconfirm(){
            if(this.identity_font&&this.identity_back){
                if(this.first_name){
                    if(this.identity_num){
                        
                        let formData = new FormData();
                        formData.append("first_name",this.first_name)
                        formData.append("identity_num",this.identity_num)
                        formData.append("identity_font",this.identity_font)
                        formData.append("identity_back",this.identity_back)

                        this.$ajax.accoutkyc(
                            formData
                        ,{
                            headers:{
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(res=>{
                            if(res.code==200){
                                // 立即判断状态
                                this.checkKyc()
                            }
                        })
                    }else{
                         Toast(this.$t('info.input_idcard_real'))
                    }
                }else{
                    Toast(this.$t('info.input_real_name'))
                }
            }else{
                Toast(this.$t('info.p_upload_idcard'))
            }
        },
        addBackImg(){
            this.$refs.back.click();
        },
        backImgChange(){
            this.identity_back = this.$refs.back.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(this.identity_back);
            var that = this;
            console.log(reader);
            reader.onload = function(e) {
                //这里的数据可以使本地图片显示到页面
                that.back_img = e.srcElement.result;
            };
        },
        addFrontImg(){
            this.$refs.download.click();
        },
        FrontImgChange(){
            this.identity_font = this.$refs.download.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(this.identity_font);
            var that = this;
            reader.onload = function(e) {
                //这里的数据可以使本地图片显示到页面
                that.front_img = e.srcElement.result;
            };

        },
    }
}
</script>
